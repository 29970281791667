<template>
  <div class="q-mb-sm q-mt-sm">
    <draggable
      v-if="value.length"
      :list="value"
      itemKey="image"
      v-bind="{
        animation: 200,
        group: 'welcome',
        disabled: false,
      }"
      @change="(evt) => onDragEnd(evt, value)"
      handle=".cursor-move"
      :component-data="{
        type: 'transition-group',
        name: 'drag-area',
        class: 'q-list q-list--dense q-list--separator',
      }"
      :forceFallback="true"
      fallbackClass="dragging-item"
    >
      <template #item="{ element: answer, index }">
        <div class="q-list--bordered">
          <q-item class="q-py-none">
            <q-item-section avatar class="cursor-move">
              <div class="q-gutter-xs">
                <q-icon name="open_with" color="grey" size="sm" />
                <q-btn dense round unelevated color="primary">{{ index + 1 }}</q-btn>
              </div>
            </q-item-section>
            <q-item-section>
              <InputEmojiSelector
                v-model="answer.title"
                :placeholder="$t('label.common.answer.name')"
                maxlength="20"
                :rules="[(val) => (val && val.length > 0) || requiredRule.answerText]"
                style="margin-top: 20px"
              />
            </q-item-section>
            <q-item-section>
              <InputEmojiSelector
                v-model="answer.label"
                :placeholder="$t('label.common.answer.name_data')"
                maxlength="20"
              />
            </q-item-section>
            <q-item-section avatar>
              <q-avatar v-if="answer.image_url">
                <img :src="answer.image_url" />
              </q-avatar>
            </q-item-section>
            <q-item-section side>
              <div class="q-gutter-xs q-pr-sm" v-if="answer.image_url">
                <q-btn size="sm" no-caps outline round icon="delete" color="red" @click="onRemoveImage(index)" />
              </div>
            </q-item-section>
            <q-item-section @click="handleFocusAnswer(index)" side>
              <div class="row">
                <div class="q-mr-sm">
                  <q-file
                    :label="$t('label.common.messages.add_image')"
                    multiple
                    outlined
                    dense
                    accept=".jpg, image/*"
                    v-model="files"
                    style="max-width: 140px"
                    bg-color="btn-upload"
                    label-color="btn-upload"
                    class="q-file-btn"
                  >
                    <template v-slot:prepend>
                      <q-icon name="attach_file" size="xs" color="white" style="font-weight: bold" />
                    </template>
                  </q-file>
                </div>
                <MediaUploader :is-crop-image="false" @on-select-media="onSelectMedia"></MediaUploader>
              </div>
            </q-item-section>

            <q-item-section side>
              <div class="q-gutter-xs">
                <q-btn
                  size="sm"
                  no-caps
                  outline
                  color="primary"
                  :disabled="value.length === 13 ? '' : isDisabled"
                  round
                  icon="add"
                  @click="handleAddAnswer(index)"
                />
                <q-btn
                  no-caps
                  outline
                  color="red"
                  size="sm"
                  icon="remove"
                  round
                  @click="handleDeleteAnswer(index)"
                  :disabled="value.length === 1 && !isDeleteFirst"
                />
              </div>
            </q-item-section>
          </q-item>
          <span v-if="index === 12">
            <div class="q-ml-sm text-red">{{ $t('label.common.answer.max_answers') }}</div>
          </span>
        </div>
      </template>
    </draggable>

    <div v-if="!value?.length" class="q-mt-sm">
      <q-btn
        no-caps
        outline
        round
        color="primary"
        :disabled="isDisabledAddAnswer"
        size="sm"
        icon="add"
        @click="handleAddAnswer(0)"
      />
    </div>
  </div>
  <UploadingProgress :files="files" />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'
import { IMedia, ITextAnswer } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import UploadApi from '@/api/upload'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'

@Options({
  components: { MediaUploader, draggable, UploadingProgress, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:quantityItem'],
})
export default class AnswerTextForm extends Vue {
  @Prop({ default: [] })
  modelValue!: ITextAnswer[]

  @Prop()
  isDeleteFirst!: boolean

  @Prop()
  quantityItemAnswers!: number

  @Prop()
  isDisabledAddAnswer!: boolean

  get requiredRule() {
    const requiredRule = {
      answerText: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.name'),
      }),
    }
    return requiredRule
  }

  countAnswer = 0
  isDisabled = false
  isAddNewDisabled = false

  files: File[] = []

  get value() {
    return this.modelValue
  }

  set value(value: ITextAnswer[]) {
    this.$emit('update:modelValue', value)
  }

  onRemoveImage(index: number) {
    if (this.value) {
      this.value[index].image_url = ''
    }
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.handleAddAnswer(0)
    }
  }

  handleAddAnswer(index: number): void {
    const newAnser: ITextAnswer = {
      _id: '',
      title: '',
      image_url: '',
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newAnser)
      this.countAnswer = index + 1
    }
    if (this.countAnswer === 12) {
      this.isDisabled = true
    }
    if (this.value.length === 1) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleDeleteAnswer(index: number): void {
    if (this.value) {
      this.value.splice(index, 1)
      this.countAnswer = index - 1
    }
    if (this.countAnswer < 13) {
      this.isDisabled = false
    }

    if (this.value.length === 0) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleFocusAnswer(index: number) {
    if (this.value) {
      this.selectAnswer = this.value[index]
    }
  }

  async onSelectMedia(file: IMedia) {
    if (file) {
      this.selectAnswer.image_url = file.url || ''
    }
  }

  @Watch('files')
  async handleUpload() {
    if (!this.files.length) {
      return false
    }
    try {
      const uploaded = await UploadApi.uploadFile(this.files[0])
      if (uploaded) {
        this.selectAnswer.image_url = uploaded.file_url_org || ''
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.files = []
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ITextAnswer[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }
}
</script>
