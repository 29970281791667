
import { Options, Vue } from 'vue-class-component'
import draggable from 'vuedraggable'
import { Prop, Watch } from 'vue-property-decorator'
import { IFormDetail, ITextAnswer } from '@/utils/types'
import { maska } from 'maska'
import { calcOrderingDragend } from '@/utils/helpers'
import { FORM_FIELD_TYPE } from '@/utils/constants'

@Options({
  components: { draggable },
  directives: { maska },
  emits: ['update:modelValue', 'update:quantityItem'],
})
export default class FormField extends Vue {
  @Prop({ default: [] })
  modelValue!: IFormDetail[]

  @Prop()
  isDeleteFirst!: boolean

  countAnswer = 0
  field_index = 0

  get value() {
    return this.modelValue
  }

  set value(value: IFormDetail[]) {
    this.$emit('update:modelValue', value)
  }

  get requiredRule() {
    return {
      fieldName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.form.field_name'),
      }),
      freeTextOption: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.freetext_option_type'),
      }),
      freeTextErrorMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.freetext_error_message'),
      }),
    }
  }

  get options() {
    return [
      {
        id: FORM_FIELD_TYPE.NAME_KANJI,
        title: this.$t('label.common.form.form_field_type_name_kanji'),
      },
      {
        id: FORM_FIELD_TYPE.NAME_HIRA,
        title: this.$t('label.common.form.form_field_type_name_hira'),
      },
      {
        id: FORM_FIELD_TYPE.NAME_KANA,
        title: this.$t('label.common.form.form_field_type_name_kana'),
      },
      {
        id: FORM_FIELD_TYPE.PHONE_NUMBER_DASH,
        title: this.$t('label.common.form.form_field_type_phone_number_dash'),
      },
      {
        id: FORM_FIELD_TYPE.PHONE_NUMBER_NO_DASH,
        title: this.$t('label.common.form.form_field_type_phone_number_no_dash'),
      },
      {
        id: FORM_FIELD_TYPE.ADDRESS,
        title: this.$t('label.common.form.form_field_type_address'),
      },
      {
        id: FORM_FIELD_TYPE.POSTCODE_DASH,
        title: this.$t('label.common.form.form_field_type_postcode_dash'),
      },
      {
        id: FORM_FIELD_TYPE.POSTCODE_NO_DASH,
        title: this.$t('label.common.form.form_field_type_postcode_no_dash'),
      },
      {
        id: FORM_FIELD_TYPE.EMAIL,
        title: this.$t('label.common.form.form_field_type_email'),
      },
      {
        id: FORM_FIELD_TYPE.FREETEX,
        title: this.$t('label.common.form.form_field_type_freetext'),
      },
    ]
  }

  handleAddAnswer(index: number): void {
    const newAnser: IFormDetail = {
      _id: '',
      field_name: this.$t('label.common.form.form_field_action_message_name_kanji'),
      option_type: FORM_FIELD_TYPE.NAME_KANJI,
      error_message: this.$t('label.common.form.form_field_error_message_name_kanji'),
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newAnser)
      this.countAnswer = index + 1
    }
    if (this.value.length === 1) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleDeleteAnswer(index: number): void {
    if (this.value) {
      this.value.splice(index, 1)
      this.countAnswer = index - 1
    }

    if (this.value.length === 0) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.handleAddAnswer(0)
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ITextAnswer[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  onHandleField(index) {
    this.field_index = index
  }

  onSelectedFieldType(val) {
    console.log(val)
    const field = this.value[this.field_index]
    if (field) {
      this.handleOptionChange(field)
    }
  }

  handleOptionChange(field) {
    if (field.option_type === FORM_FIELD_TYPE.FREETEX) {
      field.field_name = this.$t('label.common.form.form_field_action_message_freetext')
      field.error_message = ''
    } else if (field.option_type === FORM_FIELD_TYPE.NAME_KANJI) {
      field.field_name = this.$t('label.common.form.form_field_action_message_name_kanji')
      field.error_message = this.$t('label.common.form.form_field_error_message_name_kanji')
    } else if (field.option_type === FORM_FIELD_TYPE.NAME_HIRA) {
      field.field_name = this.$t('label.common.form.form_field_action_message_name_hira')
      field.error_message = this.$t('label.common.form.form_field_error_message_name_hira')
    } else if (field.option_type === FORM_FIELD_TYPE.NAME_KANA) {
      field.field_name = this.$t('label.common.form.form_field_action_message_name_kana')
      field.error_message = this.$t('label.common.form.form_field_error_message_name_kana')
    } else if (field.option_type === FORM_FIELD_TYPE.PHONE_NUMBER_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_phone_number_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_phone_number_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.PHONE_NUMBER_NO_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_phone_number_no_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_phone_number_no_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.ADDRESS) {
      field.field_name = this.$t('label.common.form.form_field_action_message_address')
      field.error_message = this.$t('label.common.form.form_field_error_message_address')
    } else if (field.option_type === FORM_FIELD_TYPE.POSTCODE_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_postcode_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_postcode_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.POSTCODE_NO_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_postcode_no_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_postcode_no_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.EMAIL) {
      field.field_name = this.$t('label.common.form.form_field_action_message_email')
      field.error_message = this.$t('label.common.form.form_field_error_message_email')
    }
  }
}
