
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IAnswer } from '@/utils/types'
import AnswerTextForm from '@/components/story/common/answer/AnswerTextForm.vue'
import ImagemapSelector from '@/components/story/common/tappable-area/selectors/ImagemapSelector.vue'
import AnswerCarouselImageForm from '@/components/story/common/answer/AnswerCarouselImageForm.vue'
import { constant, FREETEXT_OPTION_TYPE } from '@/utils/constants'

@Options({
  components: { AnswerCarouselImageForm, AnswerTextForm, ImagemapSelector },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class AnswerForm extends Vue {
  @Prop({})
  modelValue!: IAnswer

  @Prop()
  isDeleteFirst!: boolean

  @Prop()
  isDisabledAddAnswer!: boolean

  get typeLists() {
    return [
      {
        label: this.$t('label.common.answer.text'),
        value: 'text',
      },
      {
        label: this.$t('label.common.messages.image'),
        value: 'image',
      },
      {
        label: this.$t('label.common.messages.imagemap'),
        value: 'imagemap',
      },
    ]
  }

  get value() {
    return this.modelValue
  }

  set value(value: IAnswer) {
    this.$emit('update:modelValue', value)
  }

  toogleEventCalled(v) {
    console.log(this.value)
    if (v === constant.ANSWER_TYPE.IMAGEMAP) {
      this.$nextTick(() => {
        this.$refs.imagemapSelector.validate()
      })
    }
  }

  validate() {
    let isValid = true
    if (this.value.type === constant.ANSWER_TYPE.IMAGEMAP) {
      isValid = this.$refs.imagemapSelector.validate()
    } else if (this.value.type === constant.ANSWER_TYPE.IMAGE) {
      isValid = this.$refs.answerImageForm.validate()
    }

    return isValid
  }

  async created() {
    if (!this.value.freetext_answer) {
      this.value.freetext_answer = {
        option_type: FREETEXT_OPTION_TYPE.FREETEX,
        error_message: '',
      }
    }
  }
}
