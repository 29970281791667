<template>
  <span @click="copy" id="tagInputContent" class="tag-content">
    <q-input lazy-rules outlined v-model="tagValue" disable dense>
      <template v-slot:append>
        <q-icon name="content_copy" size="xs" />
        <q-tooltip> コピー </q-tooltip>
      </template>
    </q-input>
  </span>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
})
export default class TagInputContent extends Vue {
  // Props
  @Prop()
  tagValue!: string

  tagCopySuccess(): void {
    this.$q.notify({
      message: this.$t('label.copied'),
      color: 'positive',
    })
  }

  copy(): void {
    const myFluffyTextarea = document.createElement('textarea')

    myFluffyTextarea.innerHTML = this.tagValue

    const parentElement = document.getElementById('tagInputContent')
    if (!parentElement) {
      return
    }

    parentElement.appendChild(myFluffyTextarea)
    myFluffyTextarea.select()

    document.execCommand('copy')

    parentElement.removeChild(myFluffyTextarea)
    this.tagCopySuccess()
  }
}
</script>

<style lang="scss">
.tag-content .q-field .q-field__inner {
  cursor: pointer;
}
</style>
