
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'
import { IMedia, ITextAnswer } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import UploadApi from '@/api/upload'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'

@Options({
  components: { MediaUploader, draggable, UploadingProgress, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:quantityItem'],
})
export default class AnswerTextForm extends Vue {
  @Prop({ default: [] })
  modelValue!: ITextAnswer[]

  @Prop()
  isDeleteFirst!: boolean

  @Prop()
  quantityItemAnswers!: number

  @Prop()
  isDisabledAddAnswer!: boolean

  get requiredRule() {
    const requiredRule = {
      answerText: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.name'),
      }),
    }
    return requiredRule
  }

  countAnswer = 0
  isDisabled = false
  isAddNewDisabled = false

  files: File[] = []

  get value() {
    return this.modelValue
  }

  set value(value: ITextAnswer[]) {
    this.$emit('update:modelValue', value)
  }

  onRemoveImage(index: number) {
    if (this.value) {
      this.value[index].image_url = ''
    }
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.handleAddAnswer(0)
    }
  }

  handleAddAnswer(index: number): void {
    const newAnser: ITextAnswer = {
      _id: '',
      title: '',
      image_url: '',
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newAnser)
      this.countAnswer = index + 1
    }
    if (this.countAnswer === 12) {
      this.isDisabled = true
    }
    if (this.value.length === 1) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleDeleteAnswer(index: number): void {
    if (this.value) {
      this.value.splice(index, 1)
      this.countAnswer = index - 1
    }
    if (this.countAnswer < 13) {
      this.isDisabled = false
    }

    if (this.value.length === 0) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleFocusAnswer(index: number) {
    if (this.value) {
      this.selectAnswer = this.value[index]
    }
  }

  async onSelectMedia(file: IMedia) {
    if (file) {
      this.selectAnswer.image_url = file.url || ''
    }
  }

  @Watch('files')
  async handleUpload() {
    if (!this.files.length) {
      return false
    }
    try {
      const uploaded = await UploadApi.uploadFile(this.files[0])
      if (uploaded) {
        this.selectAnswer.image_url = uploaded.file_url_org || ''
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.files = []
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ITextAnswer[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }
}
