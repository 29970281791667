
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { Prop, Watch } from 'vue-property-decorator'
import { constant, SCENARIO_MAKER } from '@/utils/constants'
import { ICard } from 'bot-flow-maker/src/types'
import {
  EScenarioResourceType,
  IForm,
  IGoal,
  IInitialCampaign,
  IMessage,
  IPushCampaign,
  IQuestion,
  ISelectedItemCondition,
  IVersionHistory,
} from '@/utils/types'
import QuestionFormModal from '../question/QuestionFormModal.vue'
import MessagesFormModal from '../messages/MessagesFormModal.vue'
import GoalFormModal from '../goal/GoalFormModal.vue'
import FormFormModal from '../form/FormFormModal.vue'
import { ACTION_FORM, ACTION_GOAL, ACTION_MESSAGES, ACTION_QUESTION, VERSION_HISTORY } from '@/store/actions'
import PreviewSelectorModal from './selectors/PreviewSelectorModal.vue'
import QuestionSelectorModal from './selectors/QuestionSelectorModal.vue'
import MessageSelectorModal from './selectors/MessageSelectorModal.vue'
import GoalSelectorModal from './selectors/GoalSelectorModal.vue'
import FormSelectorModal from './selectors/FormSelectorModal.vue'
import HistorySelectorModal from './selectors/HistorySelectorModal.vue'
import moment from 'moment-timezone'
import { localStore } from '@/utils/localstore'

@Options({
  components: {
    QuestionFormModal,
    MessagesFormModal,
    GoalFormModal,
    FormFormModal,
    PreviewSelectorModal,
    QuestionSelectorModal,
    MessageSelectorModal,
    GoalSelectorModal,
    FormSelectorModal,
    HistorySelectorModal,
  },
  directives: { maska },
  emits: [
    'update:modelValue',
    'update:editingMode',
    'update:dateRange',
    'update:onChangeDirection',
    'update:onChangeScenarios',
    'update:editingStart',
  ],
})
export default class ScenariosMaker extends Vue {
  @Prop({ default: [] })
  modelValue!: ICard[]

  @Prop({ default: false })
  isReport!: boolean

  @Prop({ default: '' })
  campaignId!: string

  @Prop({ default: false })
  isTestMode!: boolean

  @Prop({ default: '0' })
  directionType!: string

  @Prop({ default: false })
  isCopy!: boolean

  @Prop()
  campaign!: IInitialCampaign | IPushCampaign

  modalQuestionlVisible = false
  modalMessageVisible = false
  modalGoalVisible = false
  modalFormVisible = false
  modalPreviewVisible = false
  modalHistoryVisible = false
  modalSelectQuestionVisible = false
  modalSelectMessageVisible = false
  modalSelectGoalVisible = false
  modalSelectFormVisible = false

  selectedItemCondition: ISelectedItemCondition = {}
  selectedEditItem: IQuestion | IMessage | IGoal | IForm = {}
  titleLastHistory = ''

  get value(): ICard[] {
    const key = constant.LOCAL_KEYS.SESSION_SCENARIOS + this.appId + '-' + this.campaignId + '-' + this.isTestMode
    const response = localStore.getWithExpiry(key)
    if (response && response.sessionScenarios.length > 0) {
      if (response.isTestMode !== this.isTestMode) {
        return this.modelValue
      }
      return response.sessionScenarios[response.currentIndex]
    }
    return this.modelValue
  }

  set value(val: ICard[]) {
    this.$emit('update:modelValue', val)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get messages() {
    return this.$store.getters.messages
  }

  get questions() {
    return this.$store.getters.questions
  }

  get goals() {
    return this.$store.getters.goals
  }

  get forms() {
    return this.$store.getters.forms
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }

    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_GOAL.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, {
      app_id: this.appId,
    })

    await this.$store.dispatch(ACTION_MESSAGES.LOAD_ITEMS, {
      app_id: this.appId,
    })
    if (!this.isReport) {
      const currentVersion: IVersionHistory = await this.$store.dispatch(VERSION_HISTORY.LOAD_ITEM_LATEST, {
        app_id: this.appId,
        campaign_id: this.campaignId,
      })
      if (currentVersion && currentVersion._id) {
        const input: string = currentVersion?.default_title + ''
        const format = 'YYYY-MM-DD HH:mm:ss'
        const zone = 'Asia/Tokyo'
        const time = moment(moment.tz(input, format, zone).utc()).fromNow()

        this.titleLastHistory = this.$t('label.last_edit_was', { lastModified: time })
      }
    }
  }

  iframeURL() {
    const path = this.$router.resolve({
      name: 'scenario_maker',
      params: {
        appId: this.appId,
        campaignId: this.campaignId,
        isReport: this.isReport,
        isTestMode: this.isTestMode,
      },
    })?.fullPath

    return path
  }

  // eslint-disable-next-line
  postToIFrame(action: string, data: any) {
    // eslint-disable-next-line
    const iframeEl = document.getElementById('myFrame-' + this.campaignId) as any
    if (!iframeEl) {
      return
    }

    iframeEl.contentWindow.postMessage(
      {
        action,
        data: {
          data: JSON.stringify(data),
          isTestMode: this.isTestMode,
        },
      },
      '*'
    )
  }

  handleIFrameReady() {
    let isStartNode = false
    if (this.value) {
      const startCards = this.value.filter((item) => item.id === EScenarioResourceType.start)
      if (startCards && startCards.length > 0) {
        isStartNode = true
      }
    }

    if (!isStartNode) {
      const startCard: ICard = {
        id: EScenarioResourceType.start,
        uniqueId: EScenarioResourceType.start,
        displayId: '',
        title: this.$t('label.start'),
        titleBadge: '',
        cardType: EScenarioResourceType.start,
        left: 100,
        top: 100,
        answers: [
          {
            id: 'any',
            title: this.$t('label.any'),
            nextCards: [],
            totalUsers: 0,
          },
        ],
        totalUsers: 0,
        labelTotalUsers: '',
      }
      this.value.push(startCard)
    }
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, this.value)
  }

  handleUpdateDataFromIFrame(data: ICard[]) {
    this.value = data
    this.$emit('update:onChangeScenarios', data)
  }

  onChangeDirection(val) {
    this.$emit('update:onChangeDirection', val)
  }

  onCloseModal() {
    // Edit modal
    this.modalQuestionlVisible = false
    this.modalMessageVisible = false
    this.modalGoalVisible = false
    this.modalFormVisible = false
    this.modalPreviewVisible = false
    this.modalSelectQuestionVisible = false
    this.modalSelectMessageVisible = false
    this.modalSelectGoalVisible = false
    this.modalSelectFormVisible = false
    this.modalPreviewVisible = false
    this.modalHistoryVisible = false
  }

  editCardDone() {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, this.value)
  }

  onCardEdit(uniqueId) {
    if (uniqueId === EScenarioResourceType.start) {
      this.$emit('update:editingStart')
    } else {
      const card = this.value.find((item) => item.uniqueId === uniqueId)
      if (card) {
        if (card.cardType === EScenarioResourceType.question) {
          this.selectedEditItem = this.questions.find((item) => item._id === card.id) || {}
          this.modalQuestionlVisible = true
        } else if (card.cardType === EScenarioResourceType.message) {
          this.selectedEditItem = this.messages.find((item) => item._id === card.id) || {}
          this.modalMessageVisible = true
        } else if (card.cardType === EScenarioResourceType.goal) {
          this.selectedEditItem = this.goals.find((item) => item._id === card.id) || {}
          this.modalGoalVisible = true
        } else if (card.cardType === EScenarioResourceType.form) {
          this.selectedEditItem = this.forms.find((item) => item._id === card.id) || {}
          this.modalFormVisible = true
        }
      }
    }
  }

  onOpenPreview() {
    this.modalPreviewVisible = true
  }

  onOpenHistory() {
    this.modalHistoryVisible = true
  }

  onSelectHistoryDone(item: IVersionHistory) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ITEM_HISTORY, item)
  }

  addCard(resource, cardType: string, selectedItemCondition: ISelectedItemCondition) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ADD_CARD, { resource, cardType, selectedItemCondition })
  }

  onSelectNextQuestionDone(question: IQuestion) {
    this.addCard(question, EScenarioResourceType.question, this.selectedItemCondition)
    this.onCloseModal()
  }

  onSelectNextMessageDone(message: IMessage) {
    this.addCard(message, EScenarioResourceType.message, this.selectedItemCondition)
    this.onCloseModal()
  }

  onSelectNextGoalDone(goal: IGoal) {
    this.addCard(goal, EScenarioResourceType.goal, this.selectedItemCondition)
    this.onCloseModal()
  }

  onSelectNextFormDone(form: IForm) {
    this.addCard(form, EScenarioResourceType.form, this.selectedItemCondition)
    this.onCloseModal()
  }

  openSelectNextQuestion(selectedItemCondition: ISelectedItemCondition) {
    this.modalSelectQuestionVisible = true
    this.selectedItemCondition = selectedItemCondition
  }

  openSelectNextMessage(selectedItemCondition: ISelectedItemCondition) {
    this.modalSelectMessageVisible = true
    this.selectedItemCondition = selectedItemCondition
  }

  openSelectNextGoal(selectedItemCondition: ISelectedItemCondition) {
    this.modalSelectGoalVisible = true
    this.selectedItemCondition = selectedItemCondition
  }

  openSelectNextForm(selectedItemCondition: ISelectedItemCondition) {
    this.modalSelectFormVisible = true
    this.selectedItemCondition = selectedItemCondition
  }

  handleMessage(event) {
    const action = event?.data?.action
    if (action === SCENARIO_MAKER.FROM_IFRAME.READY) {
      this.handleIFrameReady()
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.DIRECTION, this.directionType)
      if (!this.isReport) {
        this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.MSG_LAST_HISTORY, this.titleLastHistory)
      }
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.IFRAME_NEW_DATA) {
      this.handleUpdateDataFromIFrame(JSON.parse(event?.data?.data))
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.IS_EDITING) {
      this.$emit('update:editingMode', !!event?.data.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.DATE_RANGE) {
      this.$emit('update:dateRange', event?.data.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_CARD_EDIT) {
      this.onCardEdit(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_PREVIEW) {
      this.onOpenPreview()
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_QUESTION) {
      this.openSelectNextQuestion(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_MESSAGE) {
      this.openSelectNextMessage(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_GOAL) {
      this.openSelectNextGoal(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.OPEN_SELECT_NEXT_FORM) {
      this.openSelectNextForm(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_CHANGE_DIRECTION) {
      this.onChangeDirection(event?.data?.data)
    } else if (action === SCENARIO_MAKER.FROM_IFRAME.ON_OPEN_HISTORY) {
      this.onOpenHistory()
    }
  }

  handleErrorCard(uniqueId) {
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.ERROR_CARD, uniqueId)
  }

  handleKeyPress(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CTRL_Z, event)
    }
  }

  @Watch('isCopy')
  async onDetailCopy() {
    let scenarios: ICard[]
    if (this.isTestMode) {
      scenarios = this.campaign.prod_detail?.scenarios ?? []
    } else {
      scenarios = this.campaign.test_detail?.scenarios ?? []
    }
    this.postToIFrame(SCENARIO_MAKER.FROM_PARENT.CURRENT_VALUE, scenarios)
  }

  mounted() {
    window.addEventListener('message', (event) => this.handleMessage(event))
    window.addEventListener('keydown', this.handleKeyPress)
  }

  beforeUnmount() {
    window.removeEventListener('message', (event) => this.handleMessage(event))
    window.removeEventListener('keydown', this.handleKeyPress)
  }
}
