
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IGoal } from '@/utils/types'
import GoalFormModal from '@/components/goal/GoalFormModal.vue'

@Options({
  components: { GoalFormModal },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class GoalSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  modalGoalVisible = false
  selectedGoal: IGoal = {}

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get goals(): IGoal[] {
    return this.$store.getters.goals
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  @Emit('update:onSelect')
  onSelect(goal: IGoal) {
    return goal
  }

  onCancel() {
    this.visible = false
  }

  onAdd() {
    this.selectedGoal = {
      app_id: this.appId,
    }

    this.modalGoalVisible = true
  }

  onCloseModal() {
    this.modalGoalVisible = false
    this.selectedGoal = {}
  }
}
