
import { mixins, Options } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import { IForm } from '@/utils/types'
import { ACTION_FORM } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import FormDetail from '@/components/form/FormDetail.vue'

@Options({
  components: { FormDetail, InputEmojiSelector, TagInputContent },
})
export default class FormFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IForm

  form: IForm = {
    _id: '',
    title: '',
    app_id: '',
    message: '',
    details: [],
    applied_campain: 0,
  }

  get requiredRule() {
    return {
      formName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.form.form_name'),
      }),
    }
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.form.add_new_form') : this.$t('label.form.edit_form')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let form = null
    if (this.isNew) {
      form = await dispatch(ACTION_FORM.ADD_NEW, {
        ...this.form,
      })
    } else {
      form = await dispatch(ACTION_FORM.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (form) {
      this.$emit('update:onSelect', form)

      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()
      this.$refs.refTitle.focus()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
