<template>
  <div>
    <q-list bordered separator>
      <q-item>
        <q-item-section>
          <InputEmojiSelector v-model="altText" :placeholder="$t('label.common.answer.alt_text')" maxlength="400" />
        </q-item-section>
      </q-item>
      <q-item>
        <q-item-section>
          <q-carousel
            v-model="slide"
            ref="carousel"
            swipeable
            animated
            arrows
            control-color="grey-9"
            height="260px"
            class="rounded-borders"
            @before-transition="clickUrl = ''"
          >
            <q-carousel-slide
              class="column no-wrap flex-center"
              v-for="(imageInfo, index) in value"
              :key="index"
              :name="index + 1"
              style="padding-left: 0px; padding-right: 0px"
            >
              <div class="row">
                <div style="width: 60px; cursor: pointer" class="col-3 q-ml-xl q-mr-sm">
                  <div v-if="index > 0 && index <= value.length - 1" @click="$refs.carousel.previous()">
                    <div class="text-center image-left">
                      <q-avatar rounded size="200px">
                        <img :src="value[index - 1].image_url" />
                      </q-avatar>
                      <div class="fade-out-left"></div>
                    </div>
                  </div>
                </div>

                <div class="col-6 q-mr-md" :style="'width: 200px; '">
                  <div class="absolute-top-right" style="margin: 18px" v-if="index < value.length - 1">
                    <q-btn
                      size="sm"
                      round
                      flat
                      color="white"
                      text-color="red"
                      icon="delete"
                      class="dotted-border"
                      @click="onRemoveImage(index)"
                      style="border-color: red"
                    ></q-btn>
                  </div>
                  <div class="text-center">
                    <q-avatar rounded size="200px">
                      <img :src="imageInfo.image_url" />
                    </q-avatar>
                  </div>
                </div>
                <div class="col-3" @click="$refs.carousel.next()" style="cursor: pointer; width: 75px">
                  <div v-if="index < value.length - 1">
                    <div class="text-center image">
                      <q-avatar rounded size="200px">
                        <img :src="value[index + 1].image_url" />
                      </q-avatar>
                      <div class="fade-out-right"></div>
                    </div>
                  </div>
                  <div v-if="index == value.length - 1">
                    <div class="column items-start q-gutter-sm q-mt-xl">
                      <q-btn round flat color="white" text-color="black" icon="add" class="dotted-border" />
                      <q-btn
                        round
                        flat
                        color="white"
                        text-color="red"
                        icon="delete"
                        class="dotted-border"
                        @click="onRemoveImage(index)"
                        style="border-color: red"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="width: 100%; margin-top: 10px">
                <div class="text-black flex items-center col-2">
                  {{ $t('label.common.messages.count_images_of') }}
                  {{ index + 1 + '/' + maxImagePerMessage }}
                </div>
                <div class="col-10">
                  <q-input
                    dense
                    outlined
                    v-model="imageInfo.click_url"
                    :placeholder="$t('label.common.messages.https')"
                    lazy-rules
                  />
                </div>
              </div>
            </q-carousel-slide>
            <q-carousel-slide
              class="column no-wrap"
              :name="value.length + 1"
              style="padding-left: 0px; padding-right: 0px"
            >
              <div class="row justify-center">
                <div style="width: 60px; cursor: pointer" class="col-3 q-ml-xl q-mr-sm">
                  <div v-if="value.length > 0" @click="$refs.carousel.previous()">
                    <div class="text-center image-left">
                      <q-avatar rounded size="200px">
                        <img :src="value[value.length - 1].image_url" />
                      </q-avatar>
                      <div class="fade-out-left"></div>
                    </div>
                  </div>
                </div>
                <div class="col-3 flex items-center justify-center" style="margin-top: -10px; width: 200px">
                  <div class="column items-center">
                    <div class="column q-gutter-sm items-center">
                      <q-btn round flat color="white" text-color="black" icon="add" class="dotted-border" />
                      <div class="text-center q-mb-sm">さらに画像を追加する</div>
                    </div>
                    <div class="column q-gutter-sm">
                      <q-file
                        :label="$t('label.common.messages.add_image')"
                        outlined
                        dense
                        accept=".jpg, image/*"
                        v-model="file"
                        bg-color="btn-upload"
                        label-color="btn-upload"
                        class="q-file-btn"
                      >
                      </q-file>
                      <div>
                        <MediaUploader
                          :light-style="true"
                          :is-crop-image="true"
                          @on-select-media="onSelectMedia"
                        ></MediaUploader>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3" style="width: 75px"></div>
              </div>
              <div class="row" style="width: 100%" :style="value.length > 0 ? '' : 'margin-top: 10px'">
                <div class="text-black flex items-center col-2">
                  <span>
                    {{ $t('label.common.messages.count_images_of') }}
                    {{ value.length + '/' + maxImagePerMessage }}
                  </span>
                </div>
                <div class="col-10">
                  <q-input
                    dense
                    outlined
                    v-model="clickUrl"
                    :placeholder="$t('label.common.messages.https')"
                    lazy-rules
                  />
                </div>
              </div>
            </q-carousel-slide>
          </q-carousel>
          <div class="text-red" v-if="value.length + 1 === maxImagePerMessage">
            {{ $t('label.common.messages.max_image') }}
          </div>

          <span v-if="!isValidImage">
            <div class="text-red">{{ requiredRule.messageImage }}</div>
          </span>
        </q-item-section>
      </q-item>
    </q-list>
  </div>

  <UploadingProgress :files="cropFiles" />
  <CropperImageSelector
    v-if="cropperImageModalVisible"
    :modalVisible="cropperImageModalVisible"
    :file="file"
    @update:closeModal="onCloseModal"
    @update:onCropImage="onCropImage"
    :aspectRatio="1"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { constant } from '@/utils/constants'
import { DetailImageType, IMedia } from '@/utils/types'
import UploadApi from '@/api/upload'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'

@Options({
  components: { MediaUploader, UploadingProgress, CropperImageSelector, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:label'],
})
export default class CarouselImageSelector extends Vue {
  @Prop({ default: [] })
  modelValue!: DetailImageType[]

  @Prop()
  label!: string

  cropperImageModalVisible = false
  errorImageMessage = ''
  isValidImage = true
  file: File | null = null
  cropFiles: File[] = []

  slide = 1
  clickUrl = ''

  get requiredRule() {
    return {
      messageImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
    }
  }

  get value() {
    return this.modelValue
  }

  set value(value: DetailImageType[]) {
    this.$emit('update:modelValue', value)
  }

  get altText() {
    return this.label
  }

  set altText(value) {
    this.$emit('update:label', value)
  }

  get maxImagePerMessage() {
    return constant.MAX_IMAGE_PER_MESSAGE
  }

  validate() {
    this.isValidImage = !(this.value.length === 0)
    return this.isValidImage
  }

  onRemoveImage(index: number) {
    if (this.value) {
      this.value.splice(index, 1)
    }
  }

  async validateImageWidth(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement
        if (data.width !== data.height) {
          this.errorImageMessage = this.$t('validate.image_width_size_imagemap')
          resolve(false)
        }

        resolve(true)
      }
    })
  }

  async submitFile(file: File) {
    try {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded && this.value.length < 10) {
        this.value.push({
          image_url: uploaded.file_url_org,
          click_url: this.clickUrl,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    try {
      if (!(await this.validateImageWidth(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: '1:1',
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
          })
      } else {
        this.cropFiles.push(this.file)
        await this.submitFile(this.file)
        this.file = null
        this.cropFiles = []
      }
    } catch (error) {
      console.log(error)
    }
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.errorImageMessage = ''
    this.file = null
    this.cropFiles = []
  }

  async onCropImage(file) {
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
  }

  async onSelectMedia(file: IMedia) {
    if (file && this.value.length < 10) {
      this.value.push({
        image_url: file.url,
        click_url: this.clickUrl,
      })
    }
  }
}
</script>
<style scoped>
.image-left {
  position: relative;
  width: 100%; /* Set the visible width of each image to 70% of the container */
  overflow: hidden; /* Hide any content that overflows the container */
}

.image-left img {
  width: 200%; /* Set the image width to 200% to show 100% within the container */
  display: block;
  position: relative;
  left: -30%;
}

.fade-out-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Set the width for the fade-out effect (100% - 70%) */
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* Linear gradient for fade-out effect */
}

.image {
  width: 70%; /* Set the visible width of the image to 70% of the container */
  overflow: hidden; /* Hide any content that overflows the container */
  position: relative;
}
.image img {
  width: 100%; /* Set the image width to 100% */
  display: block;
}

.fade-out-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Set the width for the fade-out effect (100% - 70%) */
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* Linear gradient for fade-out effect */
}
</style>
