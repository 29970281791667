<template>
  <q-dialog v-model="visible">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm">{{ $t('label.common.answer.setting_tappable_areas') }}</q-toolbar-title>
        <div class="row no-wrap items-center q-gutter-sm" v-if="sessionTappables.length > 1">
          <q-btn v-if="isUndo" size="sm" flat round color="black" icon="undo" id="undo-node" @click="onUndo"> </q-btn>
          <q-btn
            v-if="currentIndex < sessionTappables.length - 1"
            size="sm"
            flat
            round
            color="black"
            icon="redo"
            id="redo-node"
            @click="onRedo"
          >
          </q-btn>
        </div>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section class="q-pa-none horizontal-line">
        <q-form ref="formRef" greedy>
          <div class="row">
            <div class="col-8 vertical-line">
              <div class="q-ml-lg q-mt-md q-pl-md">
                画像内にタップエリアを設定して、URLまたは、テキストのアクションを設定してください。
              </div>
              <div class="flex items-center justify-center">
                <div
                  class="container area-list"
                  ref="moveableContainer"
                  :style="{
                    width: defaultImage.width + 'px',
                    height: defaultImage.height + 'px',
                    backgroundSize,
                    backgroundImage,
                  }"
                >
                  <template v-for="(area, index) in value" :key="index">
                    <MoveableTappableSelector
                      :data="area"
                      :container="$refs.moveableContainer"
                      :index="index"
                      :componentKey="componentKey"
                      :length="value.length"
                    />
                  </template>
                </div>
              </div>
            </div>
            <div class="col q-pt-md">
              <TappableAreaSelector
                v-model="value"
                :isInitialMessage="isInitialMessage"
                :isPushMessage="isPushMessage"
                :isURL="isURL"
                :isMessage="isMessage"
                :isSwitchMenu="isSwitchMenu"
                :richMenuContent="richMenuContent"
                :currentMenuId="currentMenuId"
                :maxWidth="defaultImage.width"
                :maxHeight="defaultImage.height"
                @update:componentKey="componentKey++"
              />
              <span v-if="errorMsg != ''" class="q-pl-sm">
                <div class="text-red">{{ errorMsg }}</div>
              </span>
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-actions align="right" class="q-pa-md">
        <q-btn no-caps color="primary" @click="onNext"> {{ $t('next') }} </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IImageUrlSize, IRichMenuContent, ITappableArea } from '@/utils/types'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import TappableAreaSelector from '@/components/story/common/tappable-area/selectors/TappableAreaSelector.vue'
import MoveableTappableSelector from '@/components/tappable-area/selectors/MoveableTappableSelector.vue'

@Options({
  components: { InputEmojiSelector, TappableAreaSelector, MoveableTappableSelector },
  directives: { maska },
  emits: ['update:closeModal', 'update:modelValue'],
})
export default class SettingTappableModal extends Vue {
  @Prop({ default: [] })
  modelValue!: ITappableArea[]

  @Prop()
  modalVisible!: boolean

  // Default image for tappble area with 300 px
  @Prop()
  defaultImage!: IImageUrlSize

  @Prop()
  isInitialMessage!: boolean

  @Prop()
  isPushMessage!: boolean

  @Prop()
  isURL!: boolean

  @Prop()
  isMessage!: boolean

  @Prop()
  isSwitchMenu!: boolean

  @Prop()
  richMenuContent!: IRichMenuContent[]

  @Prop()
  currentMenuId!: string

  errorMsg = ''
  currentIndex = 0
  isChanged = true
  sessionTappables: ITappableArea[][] = []
  componentKey = 0

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get value() {
    return this.modelValue
  }

  set value(value: ITappableArea[]) {
    this.$emit('update:modelValue', value)
  }

  get backgroundImage() {
    if (this.defaultImage.image_url) {
      return `url(${this.defaultImage.image_url})`
    } else {
      return ''
    }
  }

  get backgroundSize() {
    if (this.defaultImage.image_url) {
      return `${this.defaultImage.width}px ${this.defaultImage.height}px`
    } else {
      return ''
    }
  }

  get isUndo() {
    return this.currentIndex > 0
  }

  get isRedo() {
    return this.currentIndex > 0 && this.currentIndex < this.sessionTappables.length - 1
  }

  validate() {
    this.errorMsg = ''
    if (this.value) {
      const tappableAreas = this.value.filter((item) => {
        if (Math.ceil(item.width ?? 0) !== 0 && Math.ceil(item.height ?? 0) !== 0) {
          return false
        }
        return true
      })

      if (tappableAreas && tappableAreas.length > 0) {
        this.errorMsg = this.$t('messages.the_width_must_be_a_positive_value')
        return false
      }
    }

    return true
  }

  onNext() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!this.validate()) {
          return false
        }

        if (!success) {
          return
        }

        this.visible = false
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  onUndo() {
    this.currentIndex = this.currentIndex - 1
    this.isChanged = false
    this.value = cloneDeep(this.sessionTappables[this.currentIndex])
    this.componentKey++
  }

  onRedo() {
    this.currentIndex = this.currentIndex + 1
    this.isChanged = false
    this.value = cloneDeep(this.sessionTappables[this.currentIndex])
    this.componentKey++
  }

  @Watch('value', { deep: true, immediate: true })
  onChangeValue() {
    if (!this.isChanged) {
      this.isChanged = true
      return
    }

    if (
      this.sessionTappables &&
      JSON.stringify(this.value) === JSON.stringify(this.sessionTappables[this.sessionTappables.length - 1])
    ) {
      return
    }

    const item = cloneDeep(this.value)
    if (item && item.length > 0) {
      this.sessionTappables.push(item)
      this.currentIndex = this.sessionTappables.length - 1
    }
  }
}
</script>

<style lang="scss" scoped>
.full-card {
  width: 1200px !important;
  max-width: 90vw !important;
}
.container {
  position: relative;
  border: 1px solid #ccc;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
