<template>
  <div class="q-mb-sm q-mt-sm">
    <draggable
      v-if="value.length"
      :list="value"
      itemKey="image"
      v-bind="{
        animation: 200,
        group: 'welcome',
        disabled: false,
      }"
      @change="(evt) => onDragEnd(evt, value)"
      handle=".cursor-move"
      :component-data="{
        type: 'transition-group',
        name: 'drag-area',
        class: 'q-list q-list--dense q-list--separator',
      }"
      :forceFallback="true"
      fallbackClass="dragging-item"
    >
      <template #item="{ element: answer, index }">
        <div class="q-py-none q-list--bordered">
          <q-item class="q-py-none">
            <q-item-section avatar class="cursor-move">
              <div class="q-gutter-xs" :class="index === 0 ? 'q-mt-sm' : ''">
                <q-icon name="open_with" color="grey" size="sm" />
                <q-btn dense round unelevated color="primary">{{ index + 1 }}</q-btn>
              </div>
            </q-item-section>
            <q-item-section :class="index === 0 ? 'first-line' : ''" @click="onHandleField(index)">
              <label class="text-right text-caption text-weight-medium q-mb-sm" v-if="index === 0">{{
                $t('label.form.format_option')
              }}</label>
              <q-select
                outlined
                dense
                option-value="id"
                option-label="title"
                v-model="answer.option_type"
                :options="options"
                emit-value
                map-options
                @update:model-value="onSelectedFieldType"
                :label="$t('label.form.format_option')"
                :rules="[(val) => (val && val.length > 0) || requiredRule.freeTextOption]"
              />
            </q-item-section>
            <q-item-section :class="index === 0 ? 'first-line' : ''">
              <label class="text-right text-caption text-weight-medium q-mb-sm" v-if="index === 0">{{
                $t('label.form.message')
              }}</label>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.fieldName]"
                outlined
                v-model="answer.field_name"
                dense
                :placeholder="$t('label.form.message')"
              />
            </q-item-section>
            <q-item-section :class="index === 0 ? 'first-line' : ''">
              <label class="text-right text-caption text-weight-medium q-mb-sm" v-if="index === 0">{{
                $t('label.form.error_message')
              }}</label>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.freeTextErrorMessage]"
                outlined
                v-model="answer.error_message"
                dense
                :placeholder="$t('label.form.error_message')"
                v-if="answer.option_type !== 'freetext'"
              />
            </q-item-section>
            <q-item-section side>
              <div class="q-gutter-xs">
                <q-btn size="sm" no-caps outline color="primary" round icon="add" @click="handleAddAnswer(index)" />
                <q-btn
                  no-caps
                  outline
                  color="red"
                  size="sm"
                  icon="remove"
                  round
                  @click="handleDeleteAnswer(index)"
                  :disabled="value.length === 1 && !isDeleteFirst"
                />
              </div>
            </q-item-section>
          </q-item>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import draggable from 'vuedraggable'
import { Prop, Watch } from 'vue-property-decorator'
import { IFormDetail, ITextAnswer } from '@/utils/types'
import { maska } from 'maska'
import { calcOrderingDragend } from '@/utils/helpers'
import { FORM_FIELD_TYPE } from '@/utils/constants'

@Options({
  components: { draggable },
  directives: { maska },
  emits: ['update:modelValue', 'update:quantityItem'],
})
export default class FormField extends Vue {
  @Prop({ default: [] })
  modelValue!: IFormDetail[]

  @Prop()
  isDeleteFirst!: boolean

  countAnswer = 0
  field_index = 0

  get value() {
    return this.modelValue
  }

  set value(value: IFormDetail[]) {
    this.$emit('update:modelValue', value)
  }

  get requiredRule() {
    return {
      fieldName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.form.field_name'),
      }),
      freeTextOption: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.freetext_option_type'),
      }),
      freeTextErrorMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.freetext_error_message'),
      }),
    }
  }

  get options() {
    return [
      {
        id: FORM_FIELD_TYPE.NAME_KANJI,
        title: this.$t('label.common.form.form_field_type_name_kanji'),
      },
      {
        id: FORM_FIELD_TYPE.NAME_HIRA,
        title: this.$t('label.common.form.form_field_type_name_hira'),
      },
      {
        id: FORM_FIELD_TYPE.NAME_KANA,
        title: this.$t('label.common.form.form_field_type_name_kana'),
      },
      {
        id: FORM_FIELD_TYPE.PHONE_NUMBER_DASH,
        title: this.$t('label.common.form.form_field_type_phone_number_dash'),
      },
      {
        id: FORM_FIELD_TYPE.PHONE_NUMBER_NO_DASH,
        title: this.$t('label.common.form.form_field_type_phone_number_no_dash'),
      },
      {
        id: FORM_FIELD_TYPE.ADDRESS,
        title: this.$t('label.common.form.form_field_type_address'),
      },
      {
        id: FORM_FIELD_TYPE.POSTCODE_DASH,
        title: this.$t('label.common.form.form_field_type_postcode_dash'),
      },
      {
        id: FORM_FIELD_TYPE.POSTCODE_NO_DASH,
        title: this.$t('label.common.form.form_field_type_postcode_no_dash'),
      },
      {
        id: FORM_FIELD_TYPE.EMAIL,
        title: this.$t('label.common.form.form_field_type_email'),
      },
      {
        id: FORM_FIELD_TYPE.FREETEX,
        title: this.$t('label.common.form.form_field_type_freetext'),
      },
    ]
  }

  handleAddAnswer(index: number): void {
    const newAnser: IFormDetail = {
      _id: '',
      field_name: this.$t('label.common.form.form_field_action_message_name_kanji'),
      option_type: FORM_FIELD_TYPE.NAME_KANJI,
      error_message: this.$t('label.common.form.form_field_error_message_name_kanji'),
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newAnser)
      this.countAnswer = index + 1
    }
    if (this.value.length === 1) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleDeleteAnswer(index: number): void {
    if (this.value) {
      this.value.splice(index, 1)
      this.countAnswer = index - 1
    }

    if (this.value.length === 0) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.handleAddAnswer(0)
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ITextAnswer[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  onHandleField(index) {
    this.field_index = index
  }

  onSelectedFieldType(val) {
    console.log(val)
    const field = this.value[this.field_index]
    if (field) {
      this.handleOptionChange(field)
    }
  }

  handleOptionChange(field) {
    if (field.option_type === FORM_FIELD_TYPE.FREETEX) {
      field.field_name = this.$t('label.common.form.form_field_action_message_freetext')
      field.error_message = ''
    } else if (field.option_type === FORM_FIELD_TYPE.NAME_KANJI) {
      field.field_name = this.$t('label.common.form.form_field_action_message_name_kanji')
      field.error_message = this.$t('label.common.form.form_field_error_message_name_kanji')
    } else if (field.option_type === FORM_FIELD_TYPE.NAME_HIRA) {
      field.field_name = this.$t('label.common.form.form_field_action_message_name_hira')
      field.error_message = this.$t('label.common.form.form_field_error_message_name_hira')
    } else if (field.option_type === FORM_FIELD_TYPE.NAME_KANA) {
      field.field_name = this.$t('label.common.form.form_field_action_message_name_kana')
      field.error_message = this.$t('label.common.form.form_field_error_message_name_kana')
    } else if (field.option_type === FORM_FIELD_TYPE.PHONE_NUMBER_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_phone_number_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_phone_number_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.PHONE_NUMBER_NO_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_phone_number_no_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_phone_number_no_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.ADDRESS) {
      field.field_name = this.$t('label.common.form.form_field_action_message_address')
      field.error_message = this.$t('label.common.form.form_field_error_message_address')
    } else if (field.option_type === FORM_FIELD_TYPE.POSTCODE_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_postcode_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_postcode_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.POSTCODE_NO_DASH) {
      field.field_name = this.$t('label.common.form.form_field_action_message_postcode_no_dash')
      field.error_message = this.$t('label.common.form.form_field_error_message_postcode_no_dash')
    } else if (field.option_type === FORM_FIELD_TYPE.EMAIL) {
      field.field_name = this.$t('label.common.form.form_field_action_message_email')
      field.error_message = this.$t('label.common.form.form_field_error_message_email')
    }
  }
}
</script>

<style scoped lang="scss">
.q-field--with-bottom {
  padding-top: 20px;
}
.first-line .q-field--with-bottom {
  padding-top: 0;
}
.first-line {
  justify-content: flex-start !important;
}
</style>
