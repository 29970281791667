
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import Moveable from 'vue3-moveable'

import { ITappableArea } from '@/utils/types'
import { IMAGEMAP_MESSAGE } from '@/utils/constants'

@Options({
  components: { Moveable },
  directives: { maska },
})
export default class MoveableTappableSelector extends Vue {
  @Prop()
  data!: ITappableArea

  @Prop()
  index!: number

  @Prop()
  container!: HTMLDivElement

  @Prop()
  componentKey!: number

  @Prop()
  length!: number

  snapDirections = {
    top: true,
    left: true,
    bottom: true,
    right: true,
    center: true,
    middle: true,
  }

  elementSnapDirections = {
    top: true,
    left: true,
    bottom: true,
    right: true,
    center: true,
    middle: true,
  }

  get elementGuidelines() {
    return Array.from({ length: this.length }, (_, index) => (index !== this.index ? `.item-${index}` : undefined)).filter((item) => item !== undefined)
  }

  get moveable() {
    return {
      target: ['.' + this.getRandomTargetId],
      draggable: true,
      resizable: true,
      throttleResize: 1,
      keepRatio: false,
      warpable: false,
      scalable: false,
      throttleScale: 0.01,
      rotatable: false,
      throttleRotate: 0.2,
      pinchable: false,
      origin: false,
    }
  }

  get getRandomTargetId() {
    return IMAGEMAP_MESSAGE.PREFIX_TARGET + Math.round(Math.random() * 1000000).toString()
  }

  get getRandomAreaId() {
    return IMAGEMAP_MESSAGE.PREFIX_AREA + Math.round(Math.random() * 1000000).toString()
  }

  handleDrag({ target, left, top }) {
    const width = this.container.offsetWidth - (this.data.width || 0)
    const height = this.container.offsetHeight - (this.data.height || 0)
    left = left < 0 ? 0 : left
    left = (left > width ? width : left).toFixed()
    top = top < 0 ? 0 : top
    top = (top > height ? height : top).toFixed()
    target.style.left = left + 'px'
    target.style.top = top + 'px'
  }

  handleDragEnd({ target }) {
    const style = window.getComputedStyle(target)
    const width = this.container.offsetWidth - (this.data.width || 0)
    const height = this.container.offsetHeight - (this.data.height || 0)
    let left = parseInt(style.getPropertyValue('left'))
    left = left < 0 ? 0 : left
    left = left > width ? width : left

    let top = parseInt(style.getPropertyValue('top'))
    top = top < 0 ? 0 : top
    top = top > height ? height : top

    target.style.left = left + 'px'
    target.style.top = top + 'px'
    if (this.data) {
      this.data.x = left
      this.data.y = top
    }
  }

  handleResize({ target, width, height, drag }) {
    const maxWidth = this.container.offsetWidth - (this.data.x || 0)
    const maxHeight = this.container.offsetHeight - (this.data.y || 0)
    width = width > maxWidth ? maxWidth : width
    height = height > maxHeight ? maxHeight : height
    let x = drag.left
    let y = drag.top
    x = x < 0 ? 0 : x
    y = y < 0 ? 0 : y
    target.style.width = `${width}px`
    target.style.height = `${height}px`
    target.style.left = x + 'px'
    target.style.top = y + 'px'
  }

  handleResizeEnd({ target }) {
    const style = window.getComputedStyle(target)
    let width = parseInt(style.getPropertyValue('width'))
    let height = parseInt(style.getPropertyValue('height'))
    let x = parseInt(style.getPropertyValue('left'))
    let y = parseInt(style.getPropertyValue('top'))

    const maxWidth = this.container.offsetWidth - (this.data.x || 0)
    const maxHeight = this.container.offsetHeight - (this.data.y || 0)
    width = width > maxWidth ? maxWidth : width
    height = height > maxHeight ? maxHeight : height
    x = x < 0 ? 0 : x
    y = y < 0 ? 0 : y
    target.style.width = `${width}px`
    target.style.height = `${height}px`
    target.style.left = x + 'px'
    target.style.top = y + 'px'
    if (this.data) {
      this.data.width = width
      this.data.height = height
      this.data.x = x
      this.data.y = y
    }
  }
}
