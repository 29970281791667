<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title> {{ $t('label.form_select') }} </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-toolbar>
        <q-btn no-caps color="primary" @click="onAdd()" :label="$t('add_new')" />
      </q-toolbar>
      <q-card-section>
        <q-list separator dense>
          <q-item v-for="form in forms" :key="form._id" clickable>
            <q-item-section class="text-bold">
              <q-item-label class="full-width ellipsis">
                {{ form.title || '-' }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn no-caps outline dense color="primary" @click="onSelect(form)" :label="$t('select')" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
  <FormFormModal
    v-if="modalFormVisible"
    :modalVisible="modalFormVisible"
    :data="selectedForm"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelect"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IForm } from '@/utils/types'
import FormFormModal from '@/components/form/FormFormModal.vue'

@Options({
  components: { FormFormModal },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class FormSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  modalFormVisible = false
  selectedForm: IForm = {}

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get forms(): IForm[] {
    return this.$store.getters.forms
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  @Emit('update:onSelect')
  onSelect(form: IForm) {
    return form
  }

  onCancel() {
    this.visible = false
  }

  onAdd() {
    this.selectedForm = {
      app_id: this.appId,
    }

    this.modalFormVisible = true
  }

  onCloseModal() {
    this.modalFormVisible = false
    this.selectedForm = {}
  }
}
</script>
