<template>
  <div class="q-mb-md">
    <draggable
      v-if="value.length"
      :list="value"
      itemKey="image"
      v-bind="{
        animation: 200,
        group: 'welcome',
        disabled: false,
      }"
      @change="(evt) => onDragEnd(evt, value)"
      handle=".cursor-move"
      :component-data="{
        type: 'transition-group',
        name: 'drag-area',
        class: 'q-list q-list--dense q-list--separator q-py-sm',
      }"
      :forceFallback="true"
      fallbackClass="dragging-item"
    >
      <template #item="{ element: answer, index }">
        <div class="bg-white">
          <q-list class="q-pb-sm">
            <q-item class="q-px-none q-mr-md">
              <q-item-section avatar class="cursor-move q-pr-md">
                <div class="row">
                  <q-icon name="swap_vert" color="grey" size="sm" />
                  <q-btn dense round unelevated class="btn-save">{{ index + 1 }}</q-btn>
                </div>
              </q-item-section>
              <q-item-section>
                <div class="row q-gutter-sm">
                  <InputEmojiSelector
                    v-model="answer.title"
                    :placeholder="$t('label.common.answer.name')"
                    maxlength="20"
                    :rules="[(val) => (val && val.length > 0) || requiredRule.answerText]"
                    class="col"
                  />
                  <InputEmojiSelector
                    v-model="answer.label"
                    :placeholder="$t('label.common.answer.name_data')"
                    maxlength="20"
                    class="col"
                  />
                </div>
              </q-item-section>
            </q-item>
            <q-item class="q-px-none q-pr-md justify-end">
              <q-item-section style="margin-left: 72px;">
                <div class="row items-center">
                  <div class="q-gutter-xs q-pr-sm">
                    <q-avatar v-if="answer.image_url" size="32px">
                      <img :src="answer.image_url" />
                    </q-avatar>
                    <q-avatar
                      size="32px"
                      font-size="18px"
                      class="dotted-border"
                      icon="image"
                      text-color="black"
                      color="grey-4"
                      v-else
                    />
                  </div>
                  <div class="q-gutter-xs q-pr-sm" v-if="answer.image_url">
                    <q-btn
                      size="sm"
                      round
                      flat
                      color="white"
                      text-color="red"
                      icon="delete"
                      class="dotted-border"
                      @click="onRemoveImage(index)"
                      style="border-color: red"
                    />
                  </div>
                </div>
              </q-item-section>
              <q-item-section @click="handleFocusAnswer(index)" side>
                <div class="row">
                  <div class="q-mr-sm col">
                    <q-file
                      :label="$t('label.common.messages.add_image')"
                      multiple
                      outlined
                      dense
                      accept=".jpg, image/*"
                      v-model="files"
                      bg-color="btn-upload"
                      label-color="btn-upload"
                      class="q-file-btn"
                    >
                    </q-file>
                  </div>
                  <div class="col">
                    <MediaUploader
                      :light-style="true"
                      :is-crop-image="false"
                      @on-select-media="onSelectMedia"
                    ></MediaUploader>
                  </div>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
          <span v-if="index === 12">
            <div class="q-ml-sm text-red">{{ $t('label.common.answer.max_answers') }}</div>
          </span>
          <div class="row items-center">
            <div class="col horizontal-line"></div>
            <q-btn
              round
              flat
              color="white"
              text-color="black"
              icon="add"
              class="solid-border"
              @click="handleAddAnswer(index)"
              :disabled="value.length === 13 ? '' : isDisabled"
            />
            <div class="horizontal-line" style="width: 20px"></div>
            <q-btn
              round
              flat
              color="white"
              text-color="black"
              icon="close"
              class="dotted-border"
              @click="handleDeleteAnswer(index)"
              :disabled="value.length === 1 && !isDeleteFirst"
            />
            <div class="col horizontal-line"></div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
  <UploadingProgress :files="files" />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'
import { IMedia, ITextAnswer } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import UploadApi from '@/api/upload'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'

@Options({
  components: { MediaUploader, draggable, UploadingProgress, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:quantityItem'],
})
export default class AnswerTextForm extends Vue {
  @Prop({ default: [] })
  modelValue!: ITextAnswer[]

  @Prop()
  isDeleteFirst!: boolean

  @Prop()
  quantityItemAnswers!: number

  @Prop()
  isDisabledAddAnswer!: boolean

  get requiredRule() {
    const requiredRule = {
      answerText: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.name'),
      }),
    }
    return requiredRule
  }

  countAnswer = 0
  isDisabled = false
  isAddNewDisabled = false

  files: File[] = []

  get value() {
    return this.modelValue
  }

  set value(value: ITextAnswer[]) {
    this.$emit('update:modelValue', value)
  }

  onRemoveImage(index: number) {
    if (this.value) {
      this.value[index].image_url = ''
    }
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.handleAddAnswer(0)
    }
  }

  handleAddAnswer(index: number): void {
    const newAnser: ITextAnswer = {
      _id: '',
      title: '',
      image_url: '',
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newAnser)
      this.countAnswer = index + 1
    }
    if (this.countAnswer === 12) {
      this.isDisabled = true
    }
    if (this.value.length === 1) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleDeleteAnswer(index: number): void {
    if (this.value) {
      this.value.splice(index, 1)
      this.countAnswer = index - 1
    }
    if (this.countAnswer < 13) {
      this.isDisabled = false
    }

    if (this.value.length === 0) {
      this.$emit('update:quantityItem', this.value.length)
    }
  }

  handleFocusAnswer(index: number) {
    if (this.value) {
      this.selectAnswer = this.value[index]
    }
  }

  async onSelectMedia(file: IMedia) {
    if (file) {
      this.selectAnswer.image_url = file.url || ''
    }
  }

  @Watch('files')
  async handleUpload() {
    if (!this.files.length) {
      return false
    }
    try {
      const uploaded = await UploadApi.uploadFile(this.files[0])
      if (uploaded) {
        this.selectAnswer.image_url = uploaded.file_url_org || ''
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.files = []
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ITextAnswer[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }
}
</script>
