
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IQuestion } from '@/utils/types'
import { ACTION_QUESTION } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { constant, FREETEXT_OPTION_TYPE } from '@/utils/constants'
// import AnswerForm from '@/components/common/answer/AnswerForm.vue'
import AnswerForm from '@/components/story/common/answer/AnswerForm.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { AnswerForm, InputEmojiSelector, TagInputContent },
  directives: { maska },
  emits: ['update:onSelect', 'update:onCreate'],
})
export default class QuestionFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IQuestion

  @Prop({ default: '' })
  appId!: string

  form: IQuestion = {
    _id: '',
    title: '',
    app_id: this.appId,
    message: '',
    answers: {
      type: constant.ANSWER_TYPE.TEXT,
      text_answers: [
        {
          _id: '',
          title: '',
          label: '',
          image_url: '',
        },
      ],
      image_answers: [],
      imagemap_answer: {},
      freetext_answer: { option_type: FREETEXT_OPTION_TYPE.FREETEX, error_message: '' },
    },
    applied_campain: 0,
  }

  get requiredRule() {
    const requiredRule = {
      questionName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.question_answer.question_answer_name'),
      }),

      questionMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew
      ? this.$t('label.question_answer.add_new_question')
      : this.$t('label.question_answer.edit_question')
  }

  get isExistTextAnswers() {
    if (
      this.form.answers &&
      this.form.answers.type === constant.ANSWER_TYPE.TEXT &&
      this.form.answers.text_answers &&
      this.form.answers.text_answers.length > 0
    ) {
      return true
    }
    return false
  }

  onSubmit() {
    const isValid = this.$refs.answerForm.validate()
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success || !isValid) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let question = null
    const isNew = this.isNew
    if (isNew) {
      question = await dispatch(ACTION_QUESTION.ADD_NEW, {
        ...this.form,
      })
    } else {
      question = await dispatch(ACTION_QUESTION.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (question) {
      if (isNew) {
        this.$emit('update:onCreate', question)
      } else {
        this.$emit('update:onSelect', question)
      }
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()

      if (this.$refs.answerForm) {
        this.$refs.answerForm.validate()
      }
      this.$refs.refTitle.focus()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
