<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card class="full-card">
      <q-toolbar class="auto-height horizontal-line">
        <q-toolbar-title class="q-pa-sm">
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <div class="row q-py-sm horizontal-line" v-if="form._id !== ''">
              <div class="q-ml-md text-grey-9 col-1 flex items-center">
                <span>
                  {{ $t('label.display_id') }}
                </span>
              </div>
              <TagInputContent :tagValue="form.display_id" v-if="form._id !== ''" class="col q-mr-md"></TagInputContent>
            </div>
            <div class="row q-py-sm horizontal-line">
              <div class="q-ml-md text-grey-9 col-1 flex items-center">
                <span>{{ $t('label.question_answer.question_answer_name') }}</span>
              </div>
              <q-input
                lazy-rules
                :rules="[(val) => (val && val.length > 0) || requiredRule.questionName]"
                outlined
                v-model="form.title"
                dense
                :placeholder="$t('label.question_answer.question_answer_name')"
                class="col q-mr-md"
                ref="refTitle"
              >
              </q-input>
            </div>
            <div class="q-my-sm q-mx-md">
              質問を用意し、テキスト・画像・画像マップのいずれかで回答を作成してください。
            </div>
            <div class="row q-py-sm horizontal-line">
              <div class="q-ml-md text-grey-9 col-1 flex items-start">
                <span>{{ $t('label.common.messages.question') }}</span>
              </div>
              <InputEmojiSelector
                v-model="form.message"
                type="textarea"
                :placeholder="$t('label.common.messages.question')"
                :rules="isExistTextAnswers ? [(val) => (val && val.length > 0) || requiredRule.questionMessage] : []"
                class="col q-mr-md"
              />
            </div>
            <div>
              <AnswerForm v-model="form.answers" ref="answerForm" />
            </div>
          </div>
          <div class="row no-wrap items-center q-pr-md">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IQuestion } from '@/utils/types'
import { ACTION_QUESTION } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { constant, FREETEXT_OPTION_TYPE } from '@/utils/constants'
// import AnswerForm from '@/components/common/answer/AnswerForm.vue'
import AnswerForm from '@/components/story/common/answer/AnswerForm.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import TagInputContent from '@/components/common/ui/TagInputContent.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { AnswerForm, InputEmojiSelector, TagInputContent },
  directives: { maska },
  emits: ['update:onSelect', 'update:onCreate'],
})
export default class QuestionFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IQuestion

  @Prop({ default: '' })
  appId!: string

  form: IQuestion = {
    _id: '',
    title: '',
    app_id: this.appId,
    message: '',
    answers: {
      type: constant.ANSWER_TYPE.TEXT,
      text_answers: [
        {
          _id: '',
          title: '',
          label: '',
          image_url: '',
        },
      ],
      image_answers: [],
      imagemap_answer: {},
      freetext_answer: { option_type: FREETEXT_OPTION_TYPE.FREETEX, error_message: '' },
    },
    applied_campain: 0,
  }

  get requiredRule() {
    const requiredRule = {
      questionName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.question_answer.question_answer_name'),
      }),

      questionMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew
      ? this.$t('label.question_answer.add_new_question')
      : this.$t('label.question_answer.edit_question')
  }

  get isExistTextAnswers() {
    if (
      this.form.answers &&
      this.form.answers.type === constant.ANSWER_TYPE.TEXT &&
      this.form.answers.text_answers &&
      this.form.answers.text_answers.length > 0
    ) {
      return true
    }
    return false
  }

  onSubmit() {
    const isValid = this.$refs.answerForm.validate()
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success || !isValid) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let question = null
    const isNew = this.isNew
    if (isNew) {
      question = await dispatch(ACTION_QUESTION.ADD_NEW, {
        ...this.form,
      })
    } else {
      question = await dispatch(ACTION_QUESTION.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (question) {
      if (isNew) {
        this.$emit('update:onCreate', question)
      } else {
        this.$emit('update:onSelect', question)
      }
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.formRef.validate()

      if (this.$refs.answerForm) {
        this.$refs.answerForm.validate()
      }
      this.$refs.refTitle.focus()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
</script>
