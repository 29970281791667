
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'
import { IPushCampaign, IRichMenuContent, ITappableArea } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import { IMAGEMAP_MESSAGE } from '@/utils/constants'
import { ACTION_PUSH } from '@/store/actions'

@Options({
  components: { draggable, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue', 'update:componentKey'],
})
export default class TappableAreaSelector extends Vue {
  @Prop({ default: [] })
  modelValue!: ITappableArea[]

  @Prop()
  isDeleteFirst!: boolean

  @Prop()
  isInitialMessage!: boolean

  @Prop()
  isPushMessage!: boolean

  @Prop()
  isURL!: boolean

  @Prop()
  isMessage!: boolean

  @Prop()
  isSwitchMenu!: boolean

  @Prop()
  richMenuContent!: IRichMenuContent[]

  @Prop()
  currentMenuId!: string

  @Prop()
  maxWidth!: number

  @Prop()
  maxHeight!: number

  get requiredRule() {
    const requiredRule = {
      answerText: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.text'),
      }),
      linkURI: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.uri'),
      }),
      actionType: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.select_action_type'),
      }),
      pushMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.select_push_message'),
      }),
      richMenu: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.select_richmenu'),
      }),
    }
    return requiredRule
  }

  get validateRule() {
    const validateRule = {
      linkURI: this.$t('validate.url_validation'),
      answerPhone: this.$t('validate.phone_validation'),
    }
    return validateRule
  }

  get options() {
    const options: [
      {
        id?: string
        title?: string
        icon?: string
      }
    ] = [{}]
    options.pop()
    if (this.isURL) {
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.URI,
        title: this.$t('label.common.answer.uri'),
        icon: 'http',
      })
    }
    if (this.isMessage) {
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.MESSAGE,
        title: 'カスタムアクション',
        icon: 'message',
      })
    }
    if (this.isInitialMessage) {
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.INITIAL,
        title: this.$t('label.common.messages.initial'),
        icon: 'send',
      })
    }

    if (this.isPushMessage) {
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.PUSH,
        title: this.$t('label.common.messages.push'),
        icon: 'send',
      })
    }

    if (this.isSwitchMenu && this.richMenus && this.richMenus?.length > 0) {
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.SWITCH_MENU,
        title: this.$t('label.common.messages.rich_menu'),
        icon: 'tabs',
      })
    }

    if (this.isInitialMessage) {
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.RESERVE,
        title: 'Reservation',
        icon: 'message',
      })

      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.PHONE_CALL,
        title: this.$t('label.common.messages.phone_call'),
        icon: 'call',
      })
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.OPEN_MAP,
        title: this.$t('label.common.messages.open_map'),
        icon: 'location_on',
      })
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.CALL_SHOP_CARD,
        title: this.$t('label.common.messages.call_shop_card'),
        icon: 'storefront',
      })
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.CALL_COUPON,
        title: this.$t('label.common.messages.call_coupon'),
        icon: 'payments',
      })
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.SHARE_CHANNEL,
        title: this.$t('label.common.messages.share_channel'),
        icon: 'share',
      })
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.CALL_ACCOUNT_PROFILE,
        title: this.$t('label.common.messages.call_account_profile'),
        icon: 'assignment_ind',
      })
      options.push({
        id: IMAGEMAP_MESSAGE.ACTION_TYPE.OPEN_LINE_VOOM,
        title: this.$t('label.common.messages.open_line_voom'),
        icon: 'feed',
      })
    }

    return options
  }

  get value() {
    return this.modelValue
  }

  set value(value: ITappableArea[]) {
    this.$emit('update:modelValue', value)
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get pushes() {
    let options:
      | [
          {
            id: string
            title: string
          }
        ]
      | undefined
    let result = this.$store.getters.pushs
    result = result.filter((item) => item.is_active === true || item.is_test_mode === true)
    for (let index = 0; index < result.length; index++) {
      const element: IPushCampaign = result[index]
      if (options) {
        options.push({
          id: element._id ?? '',
          title: element.title ?? '',
        })
      } else {
        options = [
          {
            id: element._id ?? '',
            title: element.title ?? '',
          },
        ]
      }
    }

    return options
  }

  get richMenus() {
    let options:
      | [
          {
            id: string
            title: string
          }
        ]
      | undefined

    for (let index = 0; index < this.richMenuContent.length; index++) {
      const element: IRichMenuContent = this.richMenuContent[index]
      if (element.rich_menu_alias_id !== this.currentMenuId && element.label !== '') {
        if (options) {
          options.push({
            id: element.rich_menu_alias_id ?? '',
            title: element.label ?? '',
          })
        } else {
          options = [
            {
              id: element.rich_menu_alias_id ?? '',
              title: element.label ?? '',
            },
          ]
        }
      }
    }

    return options
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.handleAdd(0)
    }
  }

  handleAdd(index: number): void {
    let action_type = ''
    if (this.options.length === 1) {
      action_type = this.options[0].id ?? ''
    }

    const tappable: ITappableArea = {
      _id: '',
      action_type: action_type,
      link_uri: '',
      title: '',
      label: '',
      x: 0,
      y: 0,
      width: IMAGEMAP_MESSAGE.DEFAULT_SIZE.WIDTH,
      height: IMAGEMAP_MESSAGE.DEFAULT_SIZE.HEIGHT,
    }
    const prev = this.value[index - 1]
    if (prev) {
      const position = this.calculatePosition(prev)
      tappable.width = prev.width
      tappable.height = prev.height
      tappable.x = position.x
      tappable.y = position.y
    }
    if (this.value) {
      this.value.splice(index + 1, 0, tappable)
    }
  }

  handleDelete(index: number): void {
    if (this.value) {
      this.value.splice(index, 1)
      this.$emit('update:componentKey')
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: ITappableArea[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }
    await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })
  }

  urlValidate(val) {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i'
    )
    return urlPattern.test(val)
  }

  phoneValidate(val) {
    // This pattern allows for various phone number formats:
    // - Optional '+', '(', and ')' at the beginning
    // - Digits, spaces, dots, hyphens, and parentheses in the middle
    // - Minimum of 6 digits required
    const phonePattern = /^(?:\+?\d{1,3}\s?)?(?:\(\d{1,4}\)|\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/

    return phonePattern.test(val)
  }

  calculatePosition(tap: ITappableArea) {
    const xPosition = tap.x ?? 0
    const yPosition = tap.y ?? 0
    const width = tap.width ?? IMAGEMAP_MESSAGE.DEFAULT_SIZE.WIDTH
    const height = tap.height ?? IMAGEMAP_MESSAGE.DEFAULT_SIZE.HEIGHT
    if (xPosition + 2 * width <= this.maxWidth) {
      return {
        x: xPosition + width,
        y: tap.y,
      }
    } else if (yPosition + 2 * height <= this.maxHeight) {
      return {
        x: tap.x,
        y: yPosition + height,
      }
    }
    return {
      x: 0,
      y: 0,
    }
  }
}
