<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title> {{ $t('label.question_select') }} </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-toolbar>
        <q-btn no-caps color="primary" @click="onAdd()" :label="$t('add_new')" />
      </q-toolbar>
      <q-card-section>
        <q-list separator dense>
          <q-item v-for="question in questions" :key="question._id" clickable>
            <q-item-section class="text-bold">
              <q-item-label class="full-width ellipsis">
                {{ question.title || '-' }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn no-caps outline dense color="primary" @click="onSelect(question)" :label="$t('select')" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
  <QuestionFormModal
    v-if="modalQuestionlVisible"
    :modalVisible="modalQuestionlVisible"
    :data="selectedQuestion"
    @update:closeModal="onCloseModal"
    @update:onSelect="onSelect"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IQuestion } from '@/utils/types'
import QuestionFormModal from '@/components/question/QuestionFormModal.vue'

@Options({
  components: { QuestionFormModal },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class QuestionSelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  modalQuestionlVisible = false
  selectedQuestion: IQuestion = {}

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get questions(): IQuestion[] {
    return this.$store.getters.questions
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  @Emit('update:onSelect')
  onSelect(question: IQuestion) {
    return question
  }

  onCancel() {
    this.visible = false
  }

  onAdd() {
    this.selectedQuestion = {
      app_id: this.appId,
    }

    this.modalQuestionlVisible = true
  }

  onCloseModal() {
    this.modalQuestionlVisible = false
    this.selectedQuestion = {}
  }
}
</script>
