
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import draggable from 'vuedraggable'

import { IMessageDetail } from '@/utils/types'
import { calcOrderingDragend } from '@/utils/helpers'
import { constant } from '@/utils/constants'
import CarouselImageSelector from './CarouselImageSelector.vue'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import ImagemapSelector from '@/components/story/common/tappable-area/selectors/ImagemapSelector.vue'

@Options({
  components: { ImagemapSelector, CarouselImageSelector, draggable, InputEmojiSelector },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class MessageSelector extends Vue {
  @Prop({ default: [] })
  modelValue!: IMessageDetail[]

  @Prop({ default: false })
  canRemoveLast!: boolean

  @Prop({ default: false })
  answerNumber!: number

  get maxItemMessage() {
    return constant.MAX_ITEM_MESSAGE - (this.answerNumber > 0 ? 1 : 0)
  }

  get typeLists() {
    const types = [
      {
        label: this.$t('label.common.messages.message'),
        value: constant.ANSWER_TYPE.MESSAGE,
      },
      {
        label: this.$t('label.common.messages.image'),
        value: constant.ANSWER_TYPE.IMAGE,
      },
      {
        label: this.$t('label.common.messages.imagemap'),
        value: constant.ANSWER_TYPE.IMAGEMAP,
      },
    ]
    return types
  }

  get requiredRule() {
    const requiredRule = {
      detailMessage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
    }
    return requiredRule
  }

  get value() {
    return this.modelValue
  }

  set value(value: IMessageDetail[]) {
    this.$emit('update:modelValue', value)
  }

  validate() {
    let isValid = true
    this.value.forEach((item, index) => {
      if (item.type === constant.ANSWER_TYPE.IMAGEMAP) {
        const id = 'imagemapSelector' + index
        if (this.$refs[id] && !this.$refs[id].validate()) {
          isValid = false
        }
      }

      if (item.type === constant.ANSWER_TYPE.IMAGE) {
        const id = 'imageSelector' + index
        if (this.$refs[id] && !this.$refs[id].validate()) {
          isValid = false
        }
      }
    })

    return isValid
  }

  @Watch('modelValue', { immediate: true })
  showDefault() {
    if (this.value.length === 0) {
      this.onAddDetail(0)
    }
  }

  onAddDetail(index: number) {
    const newDetail: IMessageDetail = {
      type: constant.ANSWER_TYPE.MESSAGE,
      message: '',
      images: [],
      imagemap_answer: {},
    }
    if (this.value) {
      this.value.splice(index + 1, 0, newDetail)
    }
  }

  onRemoveDetail(index: number) {
    if (this.value) {
      this.value.splice(index, 1)
    }
  }

  changeType(detail: IMessageDetail, updateType: string) {
    if (detail.message === '' && detail.images?.length === 0 && !detail.imagemap_answer?.base_url) {
      detail.type = updateType
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_change_this_tab'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          detail.type = updateType
          detail.message = ''
          detail.images = []
          detail.imagemap_answer = {}
        })
    }
  }

  // eslint-disable-next-line
  async onDragEnd(evt: any, finalList: IMessageDetail[]) {
    if (!evt.moved) {
      return
    }

    const record = evt.moved.element
    calcOrderingDragend(record, finalList)
  }

  async created() {
    this.value.forEach((element) => {
      if (!element.images) {
        element.images = []
      }
      if (!element.imagemap_answer) {
        element.imagemap_answer = {}
      }
      if (!element.message) {
        element.message = ''
      }
    })
  }
}
