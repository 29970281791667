
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IVersionHistory } from '@/utils/types'
import { VERSION_HISTORY } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { CLoading },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class HistorySelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  campaignId!: string

  selectedHistory: IVersionHistory = {}
  title = ''
  loading = true

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', this.currentVersion)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get versionHistories(): IVersionHistory[] {
    return this.$store.getters.version_histories
  }

  get currentVersion() {
    if (this.versionHistories && this.versionHistories.length > 0) {
      return this.versionHistories[0]
    }
  }

  @Emit('update:onSelect')
  onSelect(item: IVersionHistory) {
    this.selectedHistory = item
    this.title = this.selectedHistory.title ?? this.selectedHistory.default_title ?? ''
    return this.selectedHistory
  }

  @Watch('title')
  async handleTextChange() {
    if (this.title === '') {
      this.title = cloneDeep(this.selectedHistory.default_title) as string
    }
    if (this.title !== this.selectedHistory.title) {
      const { dispatch } = this.$store
      await dispatch(VERSION_HISTORY.UPDATE, {
        app_id: this.selectedHistory.app_id,
        _id: this.selectedHistory._id,
        title: this.title,
      })
    }
  }

  async created() {
    this.loading = true
    console.log(this.appId, 'this.appId')
    console.log(this.campaignId, 'this.campaignId')

    await this.$store.dispatch(VERSION_HISTORY.LOAD_ITEMS, {
      app_id: this.appId,
      campaign_id: this.campaignId,
    })

    if (this.currentVersion) {
      this.selectedHistory = this.currentVersion
      this.title = this.selectedHistory.title ?? this.selectedHistory.default_title ?? ''
    }

    this.loading = false
  }
}
