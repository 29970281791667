<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title> {{ $t('label.version_history') }} </q-toolbar-title>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <CLoading :loading="loading" />
        <q-list dense>
          <q-item
            v-for="item in versionHistories"
            :key="item._id"
            clickable
            :active="item._id === selectedHistory._id"
            active-class="bg-teal-5 text-grey-8"
          >
            <q-card class="full-width" @click="onSelect(item)">
              <q-card-section>
                <div class="row items-center no-wrap">
                  <div class="col">
                    <div class="text-h6" v-if="item._id === selectedHistory._id">
                      <q-input lazy-rules outlined v-model="title" dense />
                    </div>
                    <div class="text-h6" v-else>{{ item.title || item.default_title }}</div>
                    <div class="text-subtitle1" v-if="currentVersion._id === item._id">
                      {{ $t('label.current_version_history') }}
                    </div>

                    <div class="text-subtitle2 float-left">by {{ item.display_name }}</div>
                    <div class="text-subtitle2 float-right">{{ item.updated_at }}</div>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IVersionHistory } from '@/utils/types'
import { VERSION_HISTORY } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { CLoading },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class HistorySelectorModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  campaignId!: string

  selectedHistory: IVersionHistory = {}
  title = ''
  loading = true

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', this.currentVersion)
  }

  get appId() {
    return this.$route?.params?.app_id
  }

  get versionHistories(): IVersionHistory[] {
    return this.$store.getters.version_histories
  }

  get currentVersion() {
    if (this.versionHistories && this.versionHistories.length > 0) {
      return this.versionHistories[0]
    }
  }

  @Emit('update:onSelect')
  onSelect(item: IVersionHistory) {
    this.selectedHistory = item
    this.title = this.selectedHistory.title ?? this.selectedHistory.default_title ?? ''
    return this.selectedHistory
  }

  @Watch('title')
  async handleTextChange() {
    if (this.title === '') {
      this.title = cloneDeep(this.selectedHistory.default_title) as string
    }
    if (this.title !== this.selectedHistory.title) {
      const { dispatch } = this.$store
      await dispatch(VERSION_HISTORY.UPDATE, {
        app_id: this.selectedHistory.app_id,
        _id: this.selectedHistory._id,
        title: this.title,
      })
    }
  }

  async created() {
    this.loading = true
    console.log(this.appId, 'this.appId')
    console.log(this.campaignId, 'this.campaignId')

    await this.$store.dispatch(VERSION_HISTORY.LOAD_ITEMS, {
      app_id: this.appId,
      campaign_id: this.campaignId,
    })

    if (this.currentVersion) {
      this.selectedHistory = this.currentVersion
      this.title = this.selectedHistory.title ?? this.selectedHistory.default_title ?? ''
    }

    this.loading = false
  }
}
</script>
