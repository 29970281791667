
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { IImageUrlSize, IRichMenuContent, ITappableArea } from '@/utils/types'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import TappableAreaSelector from '@/components/story/common/tappable-area/selectors/TappableAreaSelector.vue'
import MoveableTappableSelector from '@/components/tappable-area/selectors/MoveableTappableSelector.vue'

@Options({
  components: { InputEmojiSelector, TappableAreaSelector, MoveableTappableSelector },
  directives: { maska },
  emits: ['update:closeModal', 'update:modelValue'],
})
export default class SettingTappableModal extends Vue {
  @Prop({ default: [] })
  modelValue!: ITappableArea[]

  @Prop()
  modalVisible!: boolean

  // Default image for tappble area with 300 px
  @Prop()
  defaultImage!: IImageUrlSize

  @Prop()
  isInitialMessage!: boolean

  @Prop()
  isPushMessage!: boolean

  @Prop()
  isURL!: boolean

  @Prop()
  isMessage!: boolean

  @Prop()
  isSwitchMenu!: boolean

  @Prop()
  richMenuContent!: IRichMenuContent[]

  @Prop()
  currentMenuId!: string

  errorMsg = ''
  currentIndex = 0
  isChanged = true
  sessionTappables: ITappableArea[][] = []
  componentKey = 0

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get value() {
    return this.modelValue
  }

  set value(value: ITappableArea[]) {
    this.$emit('update:modelValue', value)
  }

  get backgroundImage() {
    if (this.defaultImage.image_url) {
      return `url(${this.defaultImage.image_url})`
    } else {
      return ''
    }
  }

  get backgroundSize() {
    if (this.defaultImage.image_url) {
      return `${this.defaultImage.width}px ${this.defaultImage.height}px`
    } else {
      return ''
    }
  }

  get isUndo() {
    return this.currentIndex > 0
  }

  get isRedo() {
    return this.currentIndex > 0 && this.currentIndex < this.sessionTappables.length - 1
  }

  validate() {
    this.errorMsg = ''
    if (this.value) {
      const tappableAreas = this.value.filter((item) => {
        if (Math.ceil(item.width ?? 0) !== 0 && Math.ceil(item.height ?? 0) !== 0) {
          return false
        }
        return true
      })

      if (tappableAreas && tappableAreas.length > 0) {
        this.errorMsg = this.$t('messages.the_width_must_be_a_positive_value')
        return false
      }
    }

    return true
  }

  onNext() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!this.validate()) {
          return false
        }

        if (!success) {
          return
        }

        this.visible = false
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  onUndo() {
    this.currentIndex = this.currentIndex - 1
    this.isChanged = false
    this.value = cloneDeep(this.sessionTappables[this.currentIndex])
    this.componentKey++
  }

  onRedo() {
    this.currentIndex = this.currentIndex + 1
    this.isChanged = false
    this.value = cloneDeep(this.sessionTappables[this.currentIndex])
    this.componentKey++
  }

  @Watch('value', { deep: true, immediate: true })
  onChangeValue() {
    if (!this.isChanged) {
      this.isChanged = true
      return
    }

    if (
      this.sessionTappables &&
      JSON.stringify(this.value) === JSON.stringify(this.sessionTappables[this.sessionTappables.length - 1])
    ) {
      return
    }

    const item = cloneDeep(this.value)
    if (item && item.length > 0) {
      this.sessionTappables.push(item)
      this.currentIndex = this.sessionTappables.length - 1
    }
  }
}
