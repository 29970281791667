import { render } from "./TappableAreaSelector.vue?vue&type=template&id=1456fbf9&scoped=true"
import script from "./TappableAreaSelector.vue?vue&type=script&lang=ts"
export * from "./TappableAreaSelector.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-1456fbf9"

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QIcon,QBtn,QSelect,QItemLabel,QInput});
