import { render } from "./TagInputContent.vue?vue&type=template&id=04e1a5c6"
import script from "./TagInputContent.vue?vue&type=script&lang=ts"
export * from "./TagInputContent.vue?vue&type=script&lang=ts"

import "./TagInputContent.vue?vue&type=style&index=0&id=04e1a5c6&lang=scss"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QTooltip,QField});
