
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { constant } from '@/utils/constants'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import { IImageAnswer, IMedia } from '@/utils/types'
import UploadApi from '@/api/upload'
import CropperImageSelector from '@/components/tappable-area/selectors/CropperImageSelector.vue'

@Options({
  components: { MediaUploader, UploadingProgress, InputEmojiSelector, CropperImageSelector },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class AnswerCarouselImageForm extends Vue {
  @Prop({ default: [] })
  modelValue!: IImageAnswer[]

  isValidImage = true
  cropperImageModalVisible = false

  file: File | null = null
  cropFiles: File[] = []
  slide = 1
  imageTitle = ''
  imageLabel = ''

  get requiredRule() {
    return {
      answerLabel: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.answer.label'),
      }),
      answerImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
    }
  }

  get value() {
    return this.modelValue
  }

  set value(value: IImageAnswer[]) {
    this.$emit('update:modelValue', value)
  }

  get maxImagePerMessage() {
    return constant.MAX_IMAGE_PER_MESSAGE
  }

  validate() {
    this.isValidImage = !(this.value.length === 0)
    return this.isValidImage
  }

  onRemoveImage(index: number) {
    if (this.value) {
      this.value.splice(index, 1)
    }
  }

  resetImageInfo() {
    this.imageTitle = ''
    this.imageLabel = ''
  }

  @Watch('isCountImages')
  onQuantityItem() {
    this.$emit('update:quantityItem', this.isCountImages)
  }

  async onSelectMedia(file: IMedia) {
    if (file && this.value.length < 10) {
      this.value.push({
        image_url: file.url,
        label: this.imageLabel,
        title: this.imageTitle,
        _id: '',
      })
      this.resetImageInfo()
    }
  }

  async submitFile(file: File) {
    try {
      const uploaded = await UploadApi.uploadFile(file)
      if (uploaded && this.value.length < 10) {
        this.value.push({
          image_url: uploaded.file_url_org,
          label: this.imageLabel,
          title: this.imageTitle,
          _id: '',
        })
        this.resetImageInfo()
      }
    } catch (error) {
      console.log(error)
    }
  }

  async validateImageWidth(file: File) {
    return new Promise<boolean>((resolve) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = (event: Event) => {
        const data = event.target as HTMLImageElement
        if (data.width !== data.height) {
          this.errorImageMessage = this.$t('validate.image_width_size_imagemap')
          resolve(false)
        }

        resolve(true)
      }
    })
  }

  @Watch('file')
  async handleUpload() {
    if (!this.file) {
      return false
    }

    try {
      if (!(await this.validateImageWidth(this.file))) {
        await this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.image_aspect_ratio_is_not_1_1', {
              ratio: '1:1',
            }),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.cropperImageModalVisible = true
          })
          .onCancel(async () => {
            this.onCloseModal()
          })
      } else {
        this.cropFiles.push(this.file)
        await this.submitFile(this.file)
        this.file = null
        this.cropFiles = []
      }
    } catch (error) {
      console.log(error)
    }
  }

  onCloseModal() {
    this.cropperImageModalVisible = false
    this.file = null
    this.cropFiles = []
  }

  async onCropImage(file) {
    this.cropperImageModalVisible = false
    this.cropFiles.push(file)
    await this.submitFile(file)
    this.onCloseModal()
  }
}
